// deno-lint-ignore-file no-namespace

namespace MouseButton {
    export const Left = 0 as const;
    export type Left = typeof Left;

    export const Primary = Left;
    export type Primary = Left;

    export const Middle = 1 as const;
    export type Middle = typeof Middle;

    export const Auxilary = Middle;
    export type Auxilary = Middle;

    export const Right = 2 as const;
    export type Right = typeof Right;

    export const Secondary = Right;
    export type Secondary = Right;

    export const Back = 3 as const;
    export type Back = typeof Back;

    export const Fourth = Back;
    export type Fourth = Back;

    export const Forward = 4 as const;
    export type Forward = typeof Forward;

    export const Fifth = Forward;
    export type Fifth = Forward;
}
type MouseButton = typeof MouseButton[keyof typeof MouseButton];

export default MouseButton;
